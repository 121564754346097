.react-datepicker {
  box-sizing: border-box;
  border: 1px solid #dddbda;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.16);
  padding: 10px 15px;
}

.date-picker-date .react-datepicker {
  width: 251px;
}

.date-picker-month .react-datepicker {
  width: 211px;
}

.date-picker-quarter .react-datepicker {
  width: 210px;
}

.date-picker-year .react-datepicker {
  width: 211px;
}

.react-datepicker-popper {
  z-index: 1000;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__input-container {
  box-sizing: border-box;
  height: 41px;
  width: 100%;
  border: 1px solid #dddbda;
  border-radius: 4px;
  background-color: #ffffff;
  padding: 10px 12px;
}

.date-size-small .react-datepicker__input-container {
  height: 27px;
  padding: 2px 10px;
}

.date-picker-container:focus-within .react-datepicker__input-container,
.react-datepicker__input-container:focus-within {
  border-color: #007eac;
}

.has-error .react-datepicker__input-container {
  border-color: #ff2a00;
}

.react-datepicker__input-container input {
  border: none;
  width: 100%;
  padding: 0px;
  color: #000000;
  font-family: "Inter";
  font-size: 1rem;
  letter-spacing: 0;
  line-height: 18px;
  outline: none;
}

.date-size-small .react-datepicker__input-container input {
  font-size: 12px;
  line-height: 20px;
}

.react-datepicker__input-container input::placeholder {
  color: #9f9f9f;
}

.react-datepicker__input-container input::-webkit-input-placeholder {
  color: #9f9f9f;
}

.react-datepicker__input-container input:-ms-input-placeholder {
  color: #9f9f9f;
}

.react-datepicker__header {
  background: #ffffff;
  border-bottom: none;
  padding: 0px;
  padding-bottom: 10px;
}

.react-datepicker__current-month,
.react-datepicker-year-header {
  color: #000000;
  font-family: "Inter";
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 0;
  line-height: 18px;
  padding-top: 0px;
  border-radius: 9px;
  padding: 2px;
}

.react-datepicker__current-month:hover {
  background-color: #f5f2fc;
  cursor: pointer;
}

.react-datepicker__today-button {
  padding-top: 8px;
  padding-bottom: 3px;
  background: #ffffff;
  border-top: none;
  color: #000000;
  font-family: "Inter";
  font-size: 16px;
  letter-spacing: 0;
  line-height: 18px;
  font-weight: normal;
}

.react-datepicker__today-text {
  display: inline-block;
  padding: 1px 6px;
  border-radius: 12px;
}

.react-datepicker__today-text:hover {
  background-color: #f5f2fc;
}

.react-datepicker__day-names {
  padding-top: 8px;
  margin-bottom: -12px;
}

.react-datepicker__day-name {
  color: #000000;
  font-family: "Inter";
  font-size: 12px;
  letter-spacing: 0;
  line-height: 14px;
  text-align: center;
  width: 22px;
  height: 22px;
  padding: 2px;
  margin: 0px 5px;
}
.react-datepicker__day,
.react-datepicker__month-text,
.react-datepicker__quarter-text,
.react-datepicker__year-text {
  box-sizing: border-box;
  color: #000000;
  font-family: "Inter";
  font-size: 16px;
  letter-spacing: 0;
  line-height: 18px;
  text-align: center;
  width: 22px;
  height: 22px;
  border-radius: 11px;
  padding: 2px;
  margin: 2px 5px;
  outline: none;
}

.react-datepicker__quarter-text {
  width: 80px !important;
  margin-right: 5px !important;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  border-color: #f5f2fc !important;
  background-color: #f5f2fc;
  border-radius: 11px;
}

.react-datepicker__day:focus,
.react-datepicker__month-text:focus,
.react-datepicker__quarter-text:focus,
.react-datepicker__year-text:focus {
  border-color: #f5f2fc !important;
  background-color: #f5f2fc;
  border-radius: 11px;
}

.react-datepicker__day--outside-month {
  color: #9f9f9f;
}

.react-datepicker__month-wrapper {
  padding-left: 0px;
}

.react-datepicker__year-wrapper {
  padding-top: 5px;
}

.react-datepicker__month-container {
  float: none;
}
.react-datepicker__month {
  margin: 0px;
  padding-top: 5px;
}

.react-datepicker__month-text {
  width: 40px !important;
  margin-right: 1px !important;
}

.react-datepicker__year {
  margin: 0px;
}

.react-datepicker__year-text {
  width: 41px !important;
  margin-right: 1.5px !important;
}

.react-datepicker__day--today,
.react-datepicker__month-text--today,
.react-datepicker__year-text--today {
  border: solid 1px #b0adab;
  font-weight: normal;
  padding-top: 1px;
  background-color: #ffffff;

  :hover {
    background-color: #f5f2fc;
  }
}

.react-datepicker__day--selected,
.react-datepicker__month-text--selected,
.react-datepicker__quarter-text--selected,
.react-datepicker__year-text--selected {
  border-width: 0px !important;
  border-color: #0097c0;
  background-color: #0097c0 !important;
  color: #ffffff !important;
}

.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  border-color: #00c1f3 !important;
  background-color: #00c1f3 !important;
  color: #ffffff !important;
}

.react-datepicker__day--selecting-range-start,
.react-datepicker__day--selecting-range-end,
.react-datepicker__day--range-start,
.react-datepicker__day--range-end,
.react-datepicker__month-text--selecting-range-start,
.react-datepicker__month-text--selecting-range-end,
.react-datepicker__month-text--range-start,
.react-datepicker__month-text--range-end,
.react-datepicker__quarter-text--selecting-range-start,
.react-datepicker__quarter-text--selecting-range-end,
.react-datepicker__quarter-text--range-start,
.react-datepicker__quarter-text--range-end,
.react-datepicker__year-text--selecting-range-start,
.react-datepicker__year-text--selecting-range-end,
.react-datepicker__year-text--range-start,
.react-datepicker__year-text--range-end {
  border-color: #0097c0 !important;
  background-color: #0097c0 !important;
  color: #ffffff;
}

.react-datepicker__day--selecting-range-start:focus {
  background-color: #007190 !important;
}

.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled,
.react-datepicker__year-text--disabled {
  background-color: #ffffff !important;
  color: #9f9f9f;
}

.react-datepicker__navigation--previous {
  top: 10px;
  left: 8px;
  text-indent: 0px;
}

.react-datepicker__navigation--next {
  top: 10px;
  right: 10px;
  text-indent: 0px;
}

.react-datepicker__navigation {
  padding-top: 2px;
  width: 25px;
  height: 25px;
}

.react-datepicker__navigation:hover {
  background-color: #f5f2fc;
  border-radius: 50%;
}

.react-datepicker__navigation-icon--previous {
  right: 5px;
}

.react-datepicker__navigation-icon--next {
  left: -5px;
}

.react-datepicker__navigation-icon--previous::before,
.react-datepicker__navigation-icon--next::before {
  border-style: none;
}

.react-datepicker-popper[data-placement^="bottom"] {
  padding-top: 4px;
}
