:root {
  --app-background: #fdfdfc;
  --gray-1: #f2f2f2;
  --gray-2: #edeeee;
  --gray-3: #ccc;
  --gray-4: #a3a3a3;
  --gray-5: #949494;
  --gray-6: #757575;
  --rose-1: #f9efee;
  --purple-1: #9c54ed;
  --purple-2: #541299;
  --text-color: #2b0f4f;
  --link-color: #7519d6;
  --error-color: #c01717;
  --screen-x-padding: 32px;
  --content-top-padding: 112px;
  --content-max-width: 540px;
  --header-footer-height: 85px;
  --default-font-size: 13px;
  --small-font-size: 12px;
  --transition-duration: 100ms;
  --input-height: 44px;
  --border-radius: 2px;
  --font-family: "Inter", sans-serif;
}

/* -------------------- Global stuff  -------------------- */
body .content {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: var(--default-font-size);
  line-height: 150%;
  text-size-adjust: 100%;
  background-color: var(--app-background);
  color: var(--text-color);
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;

  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100dvh;

  * {
    box-sizing: border-box;
  }

  a {
    color: var(--link-color);
    text-decoration: none;
    font-size: var(--default-font-size);
  }

  a:hover {
    text-decoration: underline;
  }

  a:focus-visible {
    box-shadow: 0 0 0 2px var(--purple-1);

    /* Aestethics: Consistency to other elements focus appearance */
    border-radius: var(--border-radius);

    /* Accessibility: Below will be visible in Windows high-contrast themes, box-shadow will not */
    outline-color: transparent;
    outline-width: 2px;
    outline-style: solid;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: var(--header-footer-height);
    padding: 0 var(--screen-x-padding);
  }

  .header__logo a {
    display: flex;
    padding: 2px;
  }

  .main {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
  }

  /* -------------------- Dropdown  -------------------- */

  .dropdown {
    font-weight: 500;
    position: relative;
    display: inline-block;
    margin-left: auto;
  }

  /* Lets discuss if we should do mouseover or click to show dropdown */
  .dropdown:hover .dropdown__items {
    display: block;
  }

  .dropdown__items {
    display: none;
    position: absolute;
    right: 0;
    background-color: #fff;
    width: 300px;
    padding: 8px;
    border-radius: 4px;
    box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.12);
    margin-right: auto;
  }

  .dropdown__item {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 12px;
    color: var(--text-color);
    font-size: var(--small-font-size);
    text-decoration: none;
    border-radius: var(--border-radius);
    transition: background-color var(--transition-duration);
  }

  .dropdown__item:hover {
    background-color: var(--gray-1);
  }

  .application-message {
    padding: 0 var(--screen-x-padding);
  }

  .application-message__text {
    max-width: 800px;
    padding: 16px 24px;
    background-color: var(--rose-1);
    border-radius: var(--border-radius);
    text-align: center;
  }

  /* -------------------- Header  -------------------- */

  .main-header {
    margin-bottom: 40px;
  }

  .main-header__heading {
    margin-bottom: 12px;
    font-size: 20px;
    font-weight: 600;
    line-height: 1.25;
  }

  /* -------------------- Form -------------------- */

  .form-wrapper {
    width: 100%;
    max-width: var(--content-max-width);
    padding: var(--content-top-padding) var(--screen-x-padding) 64px;
  }

  .form-section {
    margin-bottom: 24px;
  }

  input:not([type="checkbox"]) {
    height: var(--input-height);
    width: 100%;
    padding: 0 16px;
    border: 1px solid var(--gray-3);
    border-radius: var(--border-radius);
    font-family: var(--font-family);
    font-size: var(--default-font-size);
    transition: border-color var(--transition-duration);
  }

  input:not([type="checkbox"]):not(.is-errored):not(:focus):hover {
    border-color: var(--gray-4);
  }

  input:not([type="checkbox"]):focus {
    border-color: var(--purple-1);
    box-shadow: 0 0 0 1px var(--purple-1);

    /* Accessibility: Below will be visible in Windows high-contrast themes, box-shadow will not */
    outline-color: transparent;
    outline-width: 2px;
    outline-style: solid;
  }

  input.is-errored:not([type="checkbox"]):focus {
    border-color: var(--error-color);
    box-shadow: 0 0 0 1px var(--error-color);
  }

  input.is-errored {
    border-color: var(--error-color);
  }

  input[type="checkbox"] {
    margin: 0;
    cursor: pointer;
  }

  input[type="checkbox"]:focus-visible {
    outline-color: var(--purple-1);
  }

  .password {
    position: relative;
  }

  /* Move Safari password helper icon away from toggle button */
  .password__input {
    padding-right: 60px;
  }

  .password__toggle {
    position: absolute;
    right: 6px;
    top: 7px;
    background: none;
    border: none;
    border: 1px solid transparent;
    height: auto;
    padding: 8px 12px;
    color: var(--gray-5);
    font-size: 10px;
    font-weight: bold;
    text-transform: uppercase;
    transition: color var(--transition-duration);
    cursor: pointer;
  }

  .password__toggle:focus-visible {
    border-color: var(--purple-1);
  }

  .password__toggle:hover {
    color: var(--gray-6);
    background: none;
  }

  .checkbox-wrapper {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .checkbox-label {
    font-size: var(--small-font-size);
    font-weight: 500;
    cursor: pointer;
  }

  .input__label {
    display: block;
    font-weight: 600;
  }

  .input__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 6px;
  }

  .immutable-value {
    padding: 4px 0;
  }

  .immutable-value a {
    margin-left: 4px;
  }

  .third-party__wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
    margin-bottom: 24px;
  }

  /* -------------------- Buttons and links -------------------- */

  button,
  .link-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-grow: 0;
    gap: 8px;
    height: var(--input-height);
    padding: 0 16px;
    border: none;
    background: none;
    border-radius: var(--border-radius);
    font-family: var(--font-family);
    font-size: var(--default-font-size);
    font-weight: 500;
    text-align: center;
    transition-property: background-color, border-color;
    transition-duration: var(--transition-duration);
    cursor: pointer;
  }

  button:focus-visible,
  .link-button:focus-visible {
    box-shadow: 0 0 0 1px var(--purple-1);

    /* Accessibility: Below will be visible in Windows high-contrast themes, box-shadow will not */
    outline-color: transparent;
    outline-width: 2px;
    outline-style: solid;
  }

  button.button--flat {
    border: 1px solid transparent;
    background-color: var(--gray-1);
    color: var(--text-color);
  }

  button.button--flat:hover {
    background-color: var(--gray-2);
  }

  button.button--flat:focus-visible {
    border-color: var(--purple-2);
  }

  button.button--primary {
    width: 100%;
    background-color: var(--purple-2);
    color: #fff;
  }

  button.button--primary:hover,
  button.button--primary:focus-visible {
    background-color: var(--purple-1);
    box-shadow: none;
  }

  button.button--primary:disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  .link-button--ghost {
    border: 1px solid var(--purple-2);
    color: var(--purple-2);
  }

  .link-button--ghost:hover,
  .link-button--ghost:focus-visible {
    border-color: var(--purple-1);
  }

  .link-button {
    padding: 0 4px;
  }

  .link-button:hover {
    text-decoration: none;
  }

  button svg {
    height: 20px;
    width: 20px;
    fill: currentColor;
  }

  .form-extras {
    display: flex;
    justify-content: space-between;
    font-size: var(--small-font-size);
  }

  .help-links {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    font-size: var(--small-font-size);
  }

  .help-links a + a {
    margin-top: 4px;
  }

  /* -------------------- Footer -------------------- */

  .footer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: var(--header-footer-height);
    font-size: var(--small-font-size);
  }

  .footer__wrapper {
    width: 100%;
    max-width: var(--content-max-width);
    padding: 0 var(--screen-x-padding);
  }

  .footer__row {
    display: flex;
    justify-content: center;
    text-align: center;
  }

  .footer__row + .footer__row {
    margin-top: 4px;
  }

  .footer__row.space {
    justify-content: space-between;
  }

  .text-right {
    text-align: right;
  }

  /* -------------------- Errors -------------------- */

  /* Input error messages should display if user clicks on the input, does not type email / password and leaves the field */
  /* Use the 'is-errored' class on the error message on show it */
  .input-error {
    display: none;
    font-size: var(--small-font-size);
    font-weight: 500;
    color: var(--error-color);
  }

  .input-error.is-errored {
    display: block;
  }

  /* Form error messages should display if user clicks on sign in but entered incorrect credentials */
  /* Use the 'is-errored' class on the error message on show it */
  .form-error {
    display: none;
    justify-content: center;
    padding-bottom: 24px;
    color: var(--error-color);
    font-size: var(--small-font-size);
    font-weight: 500;
  }

  .form-error.is-errored {
    display: flex;
  }

  @media (width <= 1280px), (height <= 800px) {
    :root {
      --content-top-padding: 48px;
    }
  }

  @media (width <= 540px) {
    :root {
      --screen-x-padding: 24px;
    }
  }
}
